<template>
  <div class="row">
    <div class="col-12">
      <!------------ START: Alert ------------>
      <div :class="[alertClass, field.dense && 'py-3']">
        <!------------ START: Alert icon ------------>
        <div v-if="field.icon" class="alert-icon">
          <i :class="[field.icon, field.dense && 'icon-xl']" />
        </div>
        <!------------ END: Alert icon ------------>
        <!------------ START: Alert text ------------>
        <div class="alert-text">{{ getSnippet(field.label) }}</div>
        <!------------ END: Alert text ------------>
      </div>
      <!------------ END: FieldWrapper ------------>
    </div>
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";

export default {
  components: {},
  mixins: [base],
  props: {},
  data() {
    return {};
  },
  computed: {
    alertClass: function () {
      let alert = "alert alert-custom ";
      let color = this.field.color ?? "primary";
      switch (this.field.style) {
        case "outline":
          alert += `alert-outline-${color}`;
          break;
        case "light":
          alert += `alert-light-${color}`;
          break;
        case "notice":
          alert += `alert-notice alert-light-${color}`;
          break;
        default:
          alert += `alert alert-${color}`;
          break;
      }
      return alert;
    }
  },
  mounted() {},
  methods: {}
};
</script>
